<template>
  <div class="">
    <b-row class="">
      <draggable
        v-model="dataList"
        class="w-100"
        handle=".draggable-banner-handle"
      >
        <b-col
          v-for="data in dataList"
          :key="data.id"
          cols="12"
        >
          <b-card
            no-body
            class="bannser-list-item"
          >
            <b-card-header class="">
              <b-card-title>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="18"
                  class="text-body cursor-move draggable-banner-handle"
                />
                {{ data.title }}</b-card-title
              >

              <b-dropdown
                variant="link"
                no-caret
                class="chart-dropdown"
                toggle-class="p-0"
                right
              >
                <template #button-content>
                  <feather-icon
                    icon="EditIcon"
                    size="18"
                    class="text-body cursor-pointer"
                  />
                </template>
                <b-dropdown-item
                  v-if="data.isDisplay"
                  @click="confirmResumeStop(data)"
                >
                  <feather-icon icon="PauseIcon" /> 노출중지
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!data.isDisplay"
                  @click="confirmResumeStop(data)"
                >
                  <feather-icon icon="PlayIcon" /> 노출
                </b-dropdown-item>

                <b-dropdown-item @click="routeEdit(data)">
                  <feather-icon icon="Edit3Icon" /> 수정
                </b-dropdown-item>

                <b-dropdown-item @click="confirmDelete(data)">
                  <feather-icon icon="Trash2Icon" /> 삭제
                </b-dropdown-item>
              </b-dropdown>
            </b-card-header>

            <b-img
              fluid
              :src="data.image"
            />
            <b-card-body>
              <b-card-text>{{ data.openUrl }}</b-card-text>
              <div class="text-center">
                <b-button
                  variant="outline-primary"
                  :href="data.openUrl"
                  target="_blank"
                >
                  링크 열기
                </b-button>
              </div>
            </b-card-body>
            <hr class="mb-0" />

            <div class="d-flex justify-content-between align-items-center p-2">
              <div>
                <h6 class="text-muted font-weight-bolder">시작일</h6>
                <h3 class="mb-0">{{ data.startDate }}</h3>
              </div>
              <div>
                <h6 class="text-muted font-weight-bolder">종료일</h6>
                <h3 class="mb-0">{{ data.endDate }}</h3>
              </div>
              <div>
                <h6 class="text-muted font-weight-bolder">관리번호</h6>
                <h3 class="mb-0 text-center">{{ data.id }}</h3>
              </div>
            </div>
          </b-card>
        </b-col>
      </draggable>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        class="my-2 text-right"
      >
        <b-button
          variant="success"
          class="mr-1"
          :to="{
            name: 'banner-register',
          }"
        >
          광고 등록
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  import {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCardSubTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BImg,
    BCardBody,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
  } from 'bootstrap-vue';
  import { kFormatter } from '@core/utils/filter';
  import draggable from 'vuedraggable';

  export default {
    components: {
      draggable,
      BRow,
      BCol,
      BCard,
      BFormInput,
      BCardHeader,
      BCardText,
      BCardBody,
      BCardTitle,
      BCardSubTitle,
      BMedia,
      BAvatar,
      BMediaAside,
      BMediaBody,
      BLink,
      BImg,
      BButton,
      BDropdown,
      BDropdownItem,
    },
    data() {
      return {
        dataList: [],
        aspect: '3:1',
      };
    },
    created() {
      this.getData();

      /*
      [
        {"id":1,"title":"메인 배너 - 1","openUrl":"https://google.com","sort":1,"image":"https://unsplash.com/photos/d-Cr8MEW5Uc","location":"","startDate":"2022-08-01","endDate":"2023-09-01","createdAt":"2022-08-30 20:04:53.1500000 +00:00","updatedAt":"2022-08-30 20:04:53.1500000 +00:00"},
        {"id":2,"title":"메인 배너 - 2","openUrl":"https://google.com","sort":1,"image":"https://unsplash.com/photos/d-Cr8MEW5Uc","location":"","startDate":"2022-08-01","endDate":"2023-09-01","createdAt":"2022-08-30 20:04:53.1500000 +00:00","updatedAt":"2022-08-30 20:04:53.1500000 +00:00"}
      ]
      */
    },
    methods: {
      kFormatter,
      async getData() {
        this.$http.get('/banner').then(res => {
          this.dataList = res.data;
        });
      },
      routeEdit(data) {
        this.$router.push({ name: 'banner-edit', params: { id: data.id } });
      },
      confirmDelete(data) {
        this.$swal({
          title: `배너 삭제`,
          text: `해당 배너 광고를 삭제 하시겠습니까?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '삭제',
          cancelButtonText: '취소',
          showClass: {
            popup: 'animate__animated animate__bounceIn',
          },
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.$http.delete(`/banner/${data.id}`).then(res => {
              this.getData();
            });
          }
        });
      },
      confirmResumeStop(data) {
        this.$swal({
          title: `배너 광고 ${data.isDisplay === 1 ? '중지' : ' 다시 노출'}`,
          text: `해당 배너 광고를 ${
            data.isDisplay === 1 ? '중지' : '다시 노출'
          } 하시겠습니까?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '예',
          cancelButtonText: '아니오',
          showClass: {
            popup: 'animate__animated animate__bounceIn',
          },
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            // 배너 상태 변경 ( isDisplay update )
            // id, isDisplay 전송
            const sendData = {
              id: data.id,
              isDisplay: Math.abs(data.isDisplay - 1),
            };

            this.$http.put(`/banner/update`, sendData).then(res => {
              this.getData();
            });
          }
        });
      },
    },
  };
</script>

<style>
  .bannser-list-item {
    transition: all 1s;
  }
</style>
